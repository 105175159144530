const ignoreTikTokPixel = (bugsnagEvent) => {
  if (bugsnagEvent && bugsnagEvent.errors && bugsnagEvent.errors["filter"]) {
    const errorsThatLookLikeTikTokPixel = bugsnagEvent.errors.filter( (error) => {
      if (error && error.errorMessage) {
        const looksLikeTikTokPixel = error.errorMessage.indexOf("_AutofillCallbackHandler") != -1
        return looksLikeTikTokPixel
      } else {
        return false
      }
    })
    return errorsThatLookLikeTikTokPixel.length == 0
  }
  else {
    return true
  }
}

// Export each callback separately so they can be unit tested
export { ignoreTikTokPixel }
