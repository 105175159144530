import Bugsnag from "@bugsnag/js"

import { ignoreTikTokPixel } from "./ignoreErrors"

const dasherize = (string) => {
  return string.replace(/[A-Z]/g, (char, index) => {
    if (index === 0) {
      return char.toLowerCase()
    }
    else {
      return "-" + char.toLowerCase()
    }
  })
}

const fetchRequredDataElement = (document,property) => {
  const dataAttributeName = "data-" + dasherize(property)
  const value = document.body.dataset[property]
  if (value && value.trim().length > 0) {
    return value
  }
  else {
    throw `No value for ${dataAttributeName} on the document body: ${value}`
  }
}

const enableIfNeeded = (document) => {

  const enableBugsnag = document.body.dataset.setupBugsnag === "true"
  const railsEnv      = fetchRequredDataElement(document,"railsEnv")

  let log = () => {}
  if (railsEnv === "development") {
    log = (message) => {
      console.log(`[bugsnag/enableIfNeeded]: ${message}`)
    }
  }

  if (enableBugsnag) {

    log("Bugsnag enabled for this page")

    const apiKey               = fetchRequredDataElement(document,"bugsnagJsApiKey")
    const herokuReleaseVersion = fetchRequredDataElement(document,"herokuReleaseVersion")

    const bugsnagOptions = {
      apiKey: apiKey,
      appVersion: herokuReleaseVersion,
      enabledReleaseStages: [ "production" ],
      releaseStage: railsEnv,
      onError: ignoreTikTokPixel,
    }

    log(`Bugsnag options ${JSON.stringify(bugsnagOptions)}`)

    Bugsnag.start(bugsnagOptions)
  }
  else {
    log("Bugsnag NOT enabled for this page")
  }
}

export { enableIfNeeded }
