import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.container = this.element;
    }

    checkDateOfBirth(event) {
        let dateOfBirth = event.target.valueAsDate;
        let today = new Date();
        const date18YearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

        let patientEmail = document.getElementById("clinician_referral_patient_email");
        let patientPhone = document.getElementById("clinician_referral_patient_phone");
        if (dateOfBirth > date18YearsAgo) {
            patientEmail.placeholder = "Guardian Email"
            patientPhone.placeholder = "Guardian Phone"
        } else {
            patientEmail.placeholder = "Patient Email"
            patientPhone.placeholder = "Patient Phone"
        }
    }

    clearFields(fields) {
        Array.from(fields)
            .forEach(input => input.value = "")
    }
}
